import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const LOGIN = gql`
  mutation superAdminLogin($data: LoginInput!) {
    superAdminLogin(data: $data) {
      data {
        userInfo {
          email
          name
          role
        }
        accessToken
      }
      message
      status
    }
  }
`;

export const RESTART_IMPORT_JOB = gql`
  mutation restartImportJob($jobId: Int) {
    restartImportJob(jobId: $jobId) {
      status
      message
    }
  }
`;

export const RESTART_SHARE_JOB = gql`
  mutation restartShareJob($jobId: Int) {
    restartShareJob(jobId: $jobId) {
      status
      message
    }
  }
`;
