import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';

const Logout = () => {
  const { dispatch } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    dispatch({ type: 'LOGOUT' });
    // eslint-disable-next-line no-undef
    history.push = ROUTES?.LOGIN;
  }, []);

  return null;
};

export default Logout;
