import { EyeOutlined, SyncOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Space, Tag, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  AppleSvgIcon,
  CsvSvgIcon,
  GoogleSvgIcon,
  MicrosoftSvgIcon,
  VcfSvgIcon,
} from '../../assets/svg';
import { ROUTES } from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import { RESTART_IMPORT_JOB } from '../auth/graphql/Mutations';
import { GET_JOB } from '../auth/graphql/Queries';
import RestartJobModal from './RestartJobModal';
import SystemLogsDataModal from './SystemLogsDataModal';
import './dashboard.less';

const gridStyle = {
  width: '25%',
  textAlign: 'center',
};

const { Title } = Typography;
const ViewImportJob = () => {
  const { Id } = useParams();
  const history = useHistory();
  const [getImportJobData, setGetImportJobData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [restartJobModalVisible, setRestartJobModalVisible] = useState(false);
  const [jsonDataModalVisible, setJsonDataModalVisible] = useState(false);
  const [jsonModalData, setJsonModalData] = useState([]);
  const [restartJobId, setRestartJobId] = useState();

  const [getImportJob] = useLazyQuery(GET_JOB, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      setGetImportJobData(response?.getImportJob);
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });

  const [restartImportJob] = useMutation(RESTART_IMPORT_JOB, {
    fetchPolicy: 'network-only',
    onCompleted() {
      history.push(ROUTES?.MAIN);
    },
    onError() {
      setLoading(false);
    },
  });

  useEffect(() => {
    getImportJob({
      variables: {
        importJobId: parseInt(Id, 10),
      },
    });
  }, []);

  const handleRestartJobId = (event) => {
    setRestartJobModalVisible(true);
    setRestartJobId(event);
  };

  const closeRestartModal = () => {
    setRestartJobModalVisible(false);
    setRestartJobId();
  };

  const restartJobFinish = () => {
    setLoading(true);
    restartImportJob({ variables: { jobId: restartJobId } });
    setRestartJobModalVisible(false);
    setRestartJobId();
  };

  const closeJsonModalData = () => {
    setJsonDataModalVisible(false);
    setJsonModalData([]);
  };

  const systemLogsColumns = [
    {
      title: 'Import Job ID',
      dataIndex: 'importJobId',
      key: 'importJobId',
    },
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
    },
    {
      title: 'Space ID',
      dataIndex: 'spaceId',
      key: 'spaceId',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Data',
      key: 'jsonData',
      dataIndex: 'jsonData',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Click to view JSON Data">
            <EyeOutlined
              onClick={() => {
                setJsonModalData(record?.data);
                setJsonDataModalVisible(true);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const syncLogsColumns = [
    {
      title: 'Import Job ID',
      dataIndex: 'importJobId',
      key: 'importJobId',
    },
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
    },
    {
      title: 'Space ID',
      dataIndex: 'spaceId',
      key: 'spaceId',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
  ];

  const getSourceTypeIcon = (item) => {
    switch (item) {
      case 'MICROSOFT':
        return <MicrosoftSvgIcon width={60} height={60} />;
      case 'GOOGLE':
        return <GoogleSvgIcon width={60} height={60} />;
      case 'APPLE':
        return <AppleSvgIcon width={60} height={60} />;
      case 'VCF':
        return <VcfSvgIcon width={60} height={60} />;
      case 'CSV':
        return <CsvSvgIcon width={60} height={60} />;
      default:
        return null;
    }
  };

  const getImportStatus = (item) => {
    switch (item) {
      case 'COMPLETED':
        return 'success';
      case 'PENDING':
        return 'warning';
      case 'FAILED':
        return 'error';
      case 'PROGRESS':
        return 'processing';
      default:
        return 'default';
    }
  };

  return (
    <>
      <div className="d-flex justify-between">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          Log Details
        </Title>
        <Button
          type="primary"
          onClick={() => {
            history.goBack();
          }}
        >
          Back
        </Button>
      </div>
      {loading ? (
        <LoaderComponent spinning={loading} />
      ) : (
        <>
          <div className="site-statistic-demo-card">
            {Object.keys(getImportJobData).length > 0 ? (
              <Card
                title={`#${getImportJobData?.id}`}
                extra={
                  <Button
                    icon={<SyncOutlined />}
                    onClick={() => handleRestartJobId(getImportJobData?.id)}
                  >
                    Restart Job
                  </Button>
                }
              >
                {getImportJobData?.user && (
                  <Card.Grid style={gridStyle}>
                    <p>User</p>
                    <h2>{`${getImportJobData?.user?.email} (${getImportJobData?.user?.id})`}</h2>
                  </Card.Grid>
                )}
                {getImportJobData?.importStatus && (
                  <Card.Grid style={gridStyle}>
                    <p>Import Status</p>
                    <h2>
                      <Tag
                        color={getImportStatus(getImportJobData?.importStatus)}
                      >
                        {getImportJobData?.importStatus}
                      </Tag>
                    </h2>
                  </Card.Grid>
                )}
                {getImportJobData?.sourceType && (
                  <Card.Grid style={gridStyle}>
                    <p>Source Type</p>
                    <Tooltip title={getImportJobData?.sourceType}>
                      {getSourceTypeIcon(getImportJobData?.sourceType)}
                    </Tooltip>
                  </Card.Grid>
                )}
                {getImportJobData?.contactCount !== 0 && (
                  <Card.Grid style={gridStyle}>
                    <p>Contact Count</p>
                    <h2>{getImportJobData?.contactCount}</h2>
                  </Card.Grid>
                )}
                {getImportJobData?.successCount !== 0 && (
                  <Card.Grid style={gridStyle}>
                    <p>Success Count</p>
                    <h2>{getImportJobData?.successCount}</h2>
                  </Card.Grid>
                )}
                {getImportJobData?.failureCount !== 0 && (
                  <Card.Grid style={gridStyle}>
                    <p>Failure Count</p>
                    <h2>{getImportJobData?.failureCount}</h2>
                  </Card.Grid>
                )}
                {getImportJobData?.space && (
                  <Card.Grid style={gridStyle}>
                    <p>Space</p>
                    <h2>{`${getImportJobData?.space?.name} (${getImportJobData?.space?.id})`}</h2>
                  </Card.Grid>
                )}
                {getImportJobData?.createdAt && (
                  <Card.Grid style={gridStyle}>
                    <p>Created At</p>
                    <h2>{getImportJobData?.createdAt}</h2>
                  </Card.Grid>
                )}
              </Card>
            ) : (
              <h2 className="text-center">No data found!</h2>
            )}
          </div>
          <Title className="site-page-header p-0 mb-8 mt-24" level={3}>
            System Logs Details
          </Title>
          <CommonTable
            columns={systemLogsColumns}
            loadingData={loading}
            data={getImportJobData?.systemLogs}
            className="system-logs-columns"
          />
          <Title className="site-page-header p-0 mb-8 mt-24" level={3}>
            Sync Logs Details
          </Title>
          <CommonTable
            columns={syncLogsColumns}
            loadingData={loading}
            data={getImportJobData?.syncLogs}
            className="mb-24"
          />
        </>
      )}
      {restartJobModalVisible && (
        <RestartJobModal
          visible={restartJobModalVisible}
          onCancel={closeRestartModal}
          onSub={restartJobFinish}
          restartJobId={restartJobId}
        />
      )}
      {jsonDataModalVisible && (
        <SystemLogsDataModal
          visible={jsonDataModalVisible}
          onCancel={closeJsonModalData}
          JSONData={jsonModalData}
        />
      )}
    </>
  );
};

export default ViewImportJob;
