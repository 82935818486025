import { gql } from '@apollo/client';

export const LOGOUT_USER = gql`
  mutation logout {
    logout {
      message
    }
  }
`;

export const REFRESH_TOKEN = gql`
  query refreshToken($data: RefreshTokenInput!) {
    refreshToken(data: $data) {
      token
      user {
        id
        email
        profileImage
        name
        firstName
        lastName
      }
    }
  }
`;

export const GET_IMPORTED_JOBS = gql`
  query getImportedjobs($data: JobInput!) {
    getImportJobs(data: $data) {
      data {
        jobs {
          id
          user {
            id
            name
            email
          }
          space {
            id
            name
          }
          importStatus
          systemLogs {
            id
            data
          }
          syncLogs {
            id
            message
          }
          sourceType
          dataType
          contactCount
          successCount
          failureCount
          createdAt
          updatedAt
        }
        count
      }
    }
  }
`;

export const GET_SHARE_JOBS = gql`
  query getShareJobs($data: JobInput!) {
    getShareJobs(data: $data) {
      data {
        jobs {
          id
          user {
            id
            name
            email
          }
          space {
            id
            name
          }
          source
          status
          createdAt
          updatedAt
        }
        count
      }
    }
  }
`;

export const GET_JOB = gql`
  query getImportJob($importJobId: Int!) {
    getImportJob(importJobId: $importJobId) {
      id
      importStatus
      sourceType
      dataType
      contactCount
      systemLogs {
        data
        id
        spaceId
        type
        importJobId
        userId
        message
        createdAt
      }
      syncLogs {
        id
        spaceId
        type
        importJobId
        message
        userId
        createdAt
      }
      successCount
      failureCount
      user {
        id
        name
        email
      }
      space {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;
