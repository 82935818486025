import { Col, Modal, Row } from 'antd';
import React from 'react';
import './dashboard.less';

const SystemLogsDataModal = ({ visible, onCancel, JSONData }) => (
  <>
    <Modal
      className="json-modal-data-view"
      width={800}
      title="JSON Data"
      open={visible}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
    >
      <Row>
        <Col span={24}>
          <div className="m-16">
            <pre>{JSON.stringify(JSONData, null, 2)}</pre>
          </div>
        </Col>
      </Row>
    </Modal>
  </>
);

export default SystemLogsDataModal;
