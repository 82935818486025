import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Spin } from 'antd';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { FullLogoBlack } from '../../assets/svg';
import { ROUTES } from '../../common/constants';
import { currentYear, formValidatorRules } from '../../common/utils';
import './auth.less';
import { LOGIN } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const Login = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { initializeAuth } = useContext(AppContext);
  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    onError() {},
  });

  function successCallback(accessToken, userData, refreshToken) {
    initializeAuth(accessToken, userData, refreshToken);
    history?.replace(ROUTES?.MAIN);
  }

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim().toLowerCase(),
        password: values?.password?.trim(),
      };
      const response = await loginMutate({
        variables: { data: { ...formValues } },
      });

      if (response?.data) {
        const accessToken = response?.data?.superAdminLogin?.data?.accessToken;
        const userData = response?.data?.superAdminLogin?.data?.userInfo;
        const tokenRefresh =
          response?.data?.superAdminLogin?.data?.refreshToken;

        if (successCallback) {
          successCallback(accessToken, userData, tokenRefresh);
        }
      } else {
        form?.setFieldsValue(values);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <Spin spinning={loginLoading} wrapperClassName="full-width">
            <div className="text-center  mb-48">
              <FullLogoBlack width={170} height={45} />
            </div>
            <Form
              name="Login"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              size="large"
              form={form}
            >
              <Form.Item
                name="email"
                rules={[{ required, message: 'Please enter email!' }, email]}
              >
                <Input prefix={<UserOutlined />} placeholder="Enter email" />
              </Form.Item>

              <Form.Item
                name="password"
                className="mb-8"
                rules={[{ required, message: 'Please enter password!' }]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Enter password"
                />
              </Form.Item>
              <Form.Item className=" full-width mb-8 mt-48">
                <Button
                  type="primary"
                  className="full-width"
                  htmlType="submit"
                  loading={loginLoading}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
        <div className="text-center login-copyrights">
          ContactBook © {currentYear}
        </div>
      </div>
    </div>
  );
};

export default Login;
