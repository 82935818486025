import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Form, Modal, Row } from 'antd';
import React from 'react';

const RestartJobModal = ({ visible, onSub, onCancel, restartJobId }) => {
  const [form] = Form?.useForm();

  const onFinish = () => {
    onSub();
  };

  return (
    <>
      <Modal
        width={400}
        title={
          <>
            <InfoCircleOutlined />
            <span> Restart Job</span>
          </>
        }
        open={visible}
        onOk={form?.submit}
        okText="Yes, Restart"
        onCancel={onCancel}
        destroyOnClose
      >
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Row>
            <Col span={24}>
              <Form.Item>
                <div>
                  <p>
                    Are you sure you want to restart job of id{' '}
                    <strong>{restartJobId}</strong>
                  </p>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default RestartJobModal;
