import { EyeOutlined, SyncOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Space, Tag, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CommonTable from '../../components/CommonTable';
import { RESTART_IMPORT_JOB } from '../auth/graphql/Mutations';
import { GET_IMPORTED_JOBS } from '../auth/graphql/Queries';
import RestartJobModal from './RestartJobModal';
import './dashboard.less';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const { Title } = Typography;
const Dashboard = () => {
  const history = useHistory();
  const [importedJobs, setImportedJobs] = useState([]);
  const [restartJobModalVisible, setRestartJobVisible] = useState(false);
  const [restartJobId, setRestartJobId] = useState();
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [loading, setLoading] = useState(true);
  const limit = 10;

  const [getImportedJobs] = useLazyQuery(GET_IMPORTED_JOBS, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      setImportedJobs(response?.getImportJobs?.data?.jobs);
      const pagination = {
        ...paginationProp,
        defaultPageSize: limit,
        total: response?.getImportJobs?.data?.count,
      };
      setPaginationProp(pagination);
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });

  const [restartImportJob] = useMutation(RESTART_IMPORT_JOB, {
    fetchPolicy: 'network-only',
    onCompleted() {
      getImportedJobs({
        variables: {
          data: {
            offset: 0,
            limit,
            sortBy: 'DESC',
            sortOn: 'createdAt',
          },
        },
      });
    },
    onError() {
      setLoading(false);
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);

    if (sorter?.column) {
      const sortOrder = sorter?.order === 'descend' ? 'DESC' : 'ASC';
      setPaginationProp({ ...paginationProp, ...pagination });
      setLoading(true);
      getImportedJobs({
        variables: {
          data: {
            offset: skip,
            limit: pagination?.pageSize,
            sortBy: sortOrder || 'DESC',
            sortOn: sorter?.field,
          },
        },
      });
    } else {
      setPaginationProp({ ...paginationProp, ...pagination });
      setLoading(true);

      getImportedJobs({
        variables: {
          data: {
            offset: skip,
            limit: pagination?.pageSize,
            sortBy: 'DESC',
            sortOn: 'createdAt',
          },
        },
      });
    }
  };

  useEffect(() => {
    getImportedJobs({
      variables: {
        data: {
          offset: 0,
          limit,
          sortBy: 'DESC',
          sortOn: 'createdAt',
        },
      },
    });
  }, []);

  const handleRestartJobId = (event) => {
    setRestartJobVisible(true);
    setRestartJobId(event);
  };

  const closeRestartModal = () => {
    setRestartJobVisible(false);
    setRestartJobId();
  };

  const restartJobFinish = () => {
    setPaginationProp(initialPaginationValue);
    restartImportJob({ variables: { jobId: restartJobId } });
    setLoading(true);
    setRestartJobVisible(false);
    setRestartJobId();
  };

  const getImportStatus = (item) => {
    switch (item) {
      case 'COMPLETED':
        return 'success';
      case 'PENDING':
        return 'warning';
      case 'FAILED':
        return 'error';
      case 'PROGRESS':
        return 'processing';
      default:
        return 'default';
    }
  };

  const columns = [
    {
      title: 'Job ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (_, record) => (
        <p>
          {record?.user?.email}
          {` (${record?.user?.id})`}
        </p>
      ),
    },
    {
      title: 'Import Status',
      dataIndex: 'importStatus',
      key: 'importStatus',
      render: (_, record) => (
        <Space size={[0, 8]} wrap>
          <Tag color={getImportStatus(record?.importStatus)}>
            {record?.importStatus}
          </Tag>
        </Space>
      ),
    },
    {
      title: 'Source Type',
      dataIndex: 'sourceType',
      key: 'sourceType',
    },
    {
      title: 'Contact Count',
      dataIndex: 'contactCount',
      key: 'contactCount',
    },
    {
      title: 'Success Count',
      dataIndex: 'successCount',
      key: 'successCount',
    },
    {
      title: 'Failure Count',
      dataIndex: 'failureCount',
      key: 'failureCount',
    },
    {
      title: 'Space',
      dataIndex: 'space',
      key: 'space',
      render: (_, record) => (
        <p>
          {record?.space?.name}
          {` (${record?.space?.id})`}
        </p>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="View">
            <EyeOutlined
              onClick={() => {
                history.push(`/import-job/${record?.id}`);
              }}
            />
          </Tooltip>
          <Tooltip title="Restart Job">
            <SyncOutlined onClick={() => handleRestartJobId(record?.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        Import Jobs List
      </Title>
      <div className="site-statistic-demo-card">
        <CommonTable
          columns={columns}
          loadingData={loading}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          data={importedJobs}
        />
      </div>
      {restartJobModalVisible && (
        <RestartJobModal
          visible={restartJobModalVisible}
          onCancel={closeRestartModal}
          onSub={restartJobFinish}
          restartJobId={restartJobId}
        />
      )}
    </>
  );
};

export default Dashboard;
