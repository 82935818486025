import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../Error404';
import { ROUTES } from '../common/constants';
import DashboardWrapper from '../modules/Dashboard';
import ShareJobs from '../modules/Dashboard/ShareJobs';
import ViewImportJob from '../modules/Dashboard/ViewImportJob';

const ContentRoutes = () => (
  <Switch>
    <Route path={ROUTES?.MAIN} exact component={DashboardWrapper} />
    <Route path={ROUTES?.SHARE_JOBS} exact component={ShareJobs} />
    <Route path={ROUTES?.VIEW_IMPORT_JOB} exact component={ViewImportJob} />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default ContentRoutes;
