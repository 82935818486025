import { SyncOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Space, Tag, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import CommonTable from '../../components/CommonTable';
import { RESTART_SHARE_JOB } from '../auth/graphql/Mutations';
import { GET_SHARE_JOBS } from '../auth/graphql/Queries';
import RestartJobModal from './RestartJobModal';
import './dashboard.less';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const { Title } = Typography;
const ShareJobs = () => {
  const [shareJobs, setShareJobs] = useState([]);
  const [restartJobModalVisible, setRestartJobVisible] = useState(false);
  const [restartJobId, setRestartJobId] = useState();
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [loading, setLoading] = useState(true);
  const limit = 10;

  const [getShareJobs] = useLazyQuery(GET_SHARE_JOBS, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      setShareJobs(response?.getShareJobs?.data?.jobs);
      const pagination = {
        ...paginationProp,
        defaultPageSize: limit,
        total: response?.getShareJobs?.data?.count,
      };
      setPaginationProp(pagination);
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });

  const [restartJob] = useMutation(RESTART_SHARE_JOB, {
    fetchPolicy: 'network-only',
    onCompleted() {
      getShareJobs({
        variables: {
          data: {
            offset: 0,
            limit,
            sortBy: 'DESC',
            sortOn: 'createdAt',
          },
        },
      });
    },
    onError() {
      setLoading(false);
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);

    if (sorter?.column) {
      const sortOrder = sorter?.order === 'descend' ? 'DESC' : 'ASC';
      setPaginationProp({ ...paginationProp, ...pagination });
      setLoading(true);
      getShareJobs({
        variables: {
          data: {
            offset: skip,
            limit: pagination?.pageSize,
            sortBy: sortOrder || 'DESC',
            sortOn: sorter?.field,
          },
        },
      });
    } else {
      setPaginationProp({ ...paginationProp, ...pagination });
      setLoading(true);

      getShareJobs({
        variables: {
          data: {
            offset: skip,
            limit: pagination?.pageSize,
            sortBy: 'DESC',
            sortOn: 'createdAt',
          },
        },
      });
    }
  };

  useEffect(() => {
    getShareJobs({
      variables: {
        data: {
          offset: 0,
          limit,
          sortBy: 'DESC',
          sortOn: 'createdAt',
        },
      },
    });
  }, []);

  const handleRestartJobId = (event) => {
    setRestartJobVisible(true);
    setRestartJobId(event);
  };

  const closeRestartModal = () => {
    setRestartJobVisible(false);
    setRestartJobId();
  };

  const restartJobFinish = () => {
    setPaginationProp(initialPaginationValue);
    restartJob({ variables: { jobId: restartJobId } });
    setLoading(true);
    setRestartJobVisible(false);
    setRestartJobId();
  };

  const getStatus = (item) => {
    switch (item) {
      case 'COMPLETED':
        return 'success';
      case 'PENDING':
        return 'warning';
      case 'FAILED':
        return 'error';
      case 'PROGRESS':
        return 'processing';
      default:
        return 'default';
    }
  };

  const columns = [
    {
      title: 'Job ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (_, record) => (
        <p>
          {record?.user ? `${record?.user?.email} (${record?.user?.id})` : 'NA'}
        </p>
      ),
    },
    {
      title: ' Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => (
        <Space size={[0, 8]} wrap>
          <Tag color={getStatus(record?.status)}>{record?.status}</Tag>
        </Space>
      ),
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: 'Space',
      dataIndex: 'space',
      key: 'space',
      render: (_, record) => (
        <p>
          {record?.space
            ? `${record?.space?.name} (${record?.space?.id})`
            : 'NA'}
        </p>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Restart Job">
            <SyncOutlined onClick={() => handleRestartJobId(record?.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        Share Jobs List
      </Title>
      <div className="site-statistic-demo-card">
        <CommonTable
          columns={columns}
          loadingData={loading}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          data={shareJobs}
        />
      </div>
      {restartJobModalVisible && (
        <RestartJobModal
          visible={restartJobModalVisible}
          onCancel={closeRestartModal}
          onSub={restartJobFinish}
          restartJobId={restartJobId}
        />
      )}
    </>
  );
};

export default ShareJobs;
